<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <p class="text-center font-weight-light text-h3">
          Dateneingabe Umsatzmonitor für andere Nutzer
        </p>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card>
          <v-card-text>
            <v-select
              v-model="selectedUser"
              item-text="companyName"
              return-object
              :items="allUsers"
              label="Bitte ein Unternehmen auswählen"
              solo
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-if="selectedUser !== null">
      <v-col>
        <input-table-ums-mon
          apiRouteSet="/api/admin/setCompaniDataYear"
          apiRouteGet="/api/admin/getCompaniDataYear"
          :email="selectedUser.email"
          :key="selectedUser.email"
        >
        </input-table-ums-mon>
      </v-col>
      <v-col>
        <csv-input-ums-mon
          apiRouteSet="/api/admin/setCompaniDataYear"
          :email="selectedUser.email"
          :key="selectedUser.email"
        ></csv-input-ums-mon>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";
import CsvInputUmsMon from "../../components/csvInputUmsMon.vue";
import InputTableUmsMon from "../../components/InputTableUmsMon.vue";

export default {
  components: { InputTableUmsMon, CsvInputUmsMon },
  name: "ImportDataAdmin",
  data() {
    return {
      allUsers: [],
      selectedUser: null,
    };
  },
  methods: {},
  mounted() {
    axios
      .post(this.$store.state.apiUrl + "/api/admin/getAllUsers")
      .then((res) => {
        this.allUsers = res.data.allUser;
      });
  },
};
</script>

<style></style>
